import React from "react";
import { PageLayout } from "../components/page-layout";

export const PublicPage = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          W E B S I T E
        </h1>
        <div className="content__body">
          <iframe
            src="https://payhip.com/SapiensIA"
            width="100%"
            height="100%"
            style={{
              border: "none",
              position: "absolute",
              top: "10px",
              left: 0,
              marginTop: "35px",
            }}
          />
        </div>
      </div>
    </PageLayout>
  );
};
