import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

export const MobileNavBarTabs = ({ handleClick }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div className="mobile-nav-bar__tabs">
      <MobileNavBarTab
        path="/playground"
        label="Playground"
        handleClick={handleClick}
      />
      <MobileNavBarTab path="/demo" label="Chat" handleClick={handleClick} />
      <MobileNavBarTab
        path="/public"
        label="Basilisk"
        handleClick={handleClick}
      />
      <MobileNavBarTab
        path="/experience"
        label="Experience"
        handleClick={handleClick}
      />
      {isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/protected"
            label="Services"
            handleClick={handleClick}
          />
        </>
      )}
    </div>
  );
};
