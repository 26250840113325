import React from "react";
import { NavLink } from "react-router-dom";

export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__logo"
          src="https://raw.githubusercontent.com/Vortextech01/astro/main/examples/portfolio/public/assets/4AF2B273-67AB-43DD-BD60-291F641C68F8.gif"
          alt="sapiens shield logo"
          width="90"
          height="90"
        />
      </NavLink>
    </div>
  );
};
