import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src="https://payhip.com/cdn-cgi/image/format=auto,width=500/https://pe56d.s3.amazonaws.com/o_1gs8umjlv1krs1pg3m491jqt1n7co.png"
          alt="sapiens ia shield logo"
          width="200"
          height="200"
        />
      </NavLink>
    </div>
  );
};
