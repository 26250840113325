import React from "react";
import { PageLayout } from "../components/page-layout";

export const DemoPage = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          C H A T
        </h1>
        <div className="content__body">
          <iframe
            src="https://chats0--carlosdavila14.repl.co"
            width="100%"
            height="100%"
            style={{ border: "none", position: "absolute", top: 0, left: 0 }}
          />
        </div>
      </div>
    </PageLayout>
  );
};
