import React from "react";
import { PageLayout } from "../components/page-layout";

export const ProtectedPage = () => {
  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          SERVICES
        </h1>
        <div className="content__body" style={{ padding: "5px" }}>
          <iframe
            src="https://payhip.com/SapiensIA/services"
            width="100%"
            height="100%"
            style={{ border: "none", position: "absolute", top: 0, left: 0 }}
          />
        </div>
      </div>
    </PageLayout>
  );
};
