import React from "react";

export const HomePage = () => {
  return (
    <div className="content-layout">
      <h1 id="page-title" className="content__title">
        H O M E
      </h1>
      <div className="content__body">
        <iframe
          src="https://ai.sapienslaboratories.com"
          width="100%"
          height="100%"
          style={{ border: "none", position: "absolute", top: 0, left: 0 }}
        />
      </div>
    </div>
  );
};
