import React from "react";

export const PageLoader = () => {
  const loadingImg =
    "https://raw.githubusercontent.com/Vortextech01/Basilisk.V.0.7/main/public/8FAD4BB2-FA75-460A-9344-7281E3-unscreen.gif";

  return (
    <div className="loader">
      <img src={loadingImg} alt="Loading..." />
    </div>
  );
};
